<script setup lang="ts">
import { apiAuth, apiUser } from '@/services';
import { apiSettings } from '@/services/api/settings';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const isCatalogPage = computed(() => route.path === '/catalog')
const isConfirmPage = computed(() => route.path === '/confirm')
const isCartPage = computed(() => route.path === '/cart')

const defaultCurrentRegion = String(process.env.DOMAIN).indexOf('flowersmarket') !== -1
  ? 'Москва' // 'Washington'
  : 'Москва'

const defaultRegionKey = String(process.env.DOMAIN).indexOf('flowersmarket') !== -1
  ? 'regionRUS' // 'regionUSA'
  : 'regionRUS'

const dataSettings = reactive({
  id: '65a5049b7f87a487af9051a0' as string,
  regionRUS: [] as { name: string, margin: number, deliveryRegion: number, deliveryСity: number, deliveryDay: number, id: string }[],
  regionUSA: [] as { name: string, margin: number, deliveryRegion: number, deliveryСity: number, deliveryDay: number, id: string }[],
  currentRegion: defaultCurrentRegion as string
});

async function getRegions() {
  try {
    const regionsGet = await apiSettings.getSettings(dataSettings.id)
    const auth = await apiAuth.authentication()
    if (regionsGet.data && regionsGet.data.regionRUS) {
      dataSettings.regionRUS = regionsGet.data.regionRUS
    }
    if (regionsGet.data && regionsGet.data.regionUSA) {
      dataSettings.regionUSA = regionsGet.data.regionUSA
    }
    if (auth && auth.data && auth.data.region) {
      dataSettings.currentRegion = auth.data.region
    }
  } catch (error) {
    console.log(error);
  }
}
getRegions()

const updateRegion = async (name: string) => {
  try {
    const update = await apiUser.updateRegionUser(name)
    if (update.data && update.data.region) {
      dataSettings.currentRegion = update.data.region
      await userStore.updateDataUser()
      if (isCatalogPage.value || isConfirmPage.value || isCartPage.value) {
        router.go(0)
      }
    }
  } catch (error) {
    console.log(error);
  }
}

withDefaults(
  defineProps<{ buttonMod?: 'btn-small' | 'btn-accent' | 'btn-white' }>(),
  {
    buttonMod: 'btn-small',
  },
)
</script>

<template>
  <common-drop :container="{ top: '44px', left: '-60px', right: '-60px' }" :item-class="'locale-item'">
    <template #header>
      <common-button :text="dataSettings.currentRegion" :class-mods="buttonMod"></common-button>
    </template>
    <template #container="{ toggle, itemClass }">
      <ul :class="`${itemClass}-container`">
        <li
          :class="[itemClass, { 'text-red-500': locale.name !== dataSettings.currentRegion, 'text-black': locale.name === dataSettings.currentRegion }]"
          v-for="locale of dataSettings[defaultRegionKey]" :key="locale.id"
          @click="() => { toggle(); updateRegion(locale.name); }">
          <div class="locale-link full-pseudo">
            {{ locale.name }}
          </div>
        </li>
      </ul>
    </template>
  </common-drop>
</template>

<style scoped lang="scss">
.locale {
  &-item {
    @apply flex items-center justify-center gap-3 p-2 cursor-pointer relative;

    &:not(:last-child) {
      @apply border-b border-solid border-b-gray-4;
    }
  }

  &-item-container {
    @apply border border-solid border-gray-4 rounded-md max-h-60 overflow-auto;
  }

  &-link {
    @apply font-medium transition-colors duration-300;

    &.full-pseudo::after {
      @apply content-[''] absolute top-0 right-0 bottom-0 left-0;
    }
  }
}

.text-red-500 {
  color: #c73b2e;
}

.text-black {
  color: #1e1e1e;
}
</style>