<script setup lang="ts"></script>

<template>
  <section class="header-profile flex flex-ic"> 
    <common-langswitcher></common-langswitcher>
    <common-regionswitcher></common-regionswitcher>
    <common-page-header-profile-cart></common-page-header-profile-cart>
    <common-page-header-profile-account></common-page-header-profile-account>
    <common-page-header-profile-avatar></common-page-header-profile-avatar>
  </section>
</template>

<style scoped lang="scss">
.header-profile {
  @apply gap-4;
}
</style>
