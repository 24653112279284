<script setup lang="ts">
  import _ from 'lodash'; // NOTE: не поддерживает все module.export
  const { throttle } = _;

  const cart = useCartStore()

  const isShowMenu = ref(false)
  const isShowHeader = ref(false)

  const lastScroll = ref(0)

  const throttledHandleScroll = ref()

  const headerClasses = computed(() => [
    'header',
    {
      'header--is-show': isShowHeader.value,
    }
  ])

  onMounted(() => {
    cart.initCart()

    if (process.client && window) {
      throttledHandleScroll.value = throttle(handleScroll, 300)

      if (window.scrollY < 128) {
        isShowHeader.value = true
      }

      window.addEventListener('scroll', throttledHandleScroll.value)
    }
  })

  onUnmounted(() => {
    if (process.client && window && throttledHandleScroll.value) {
      window.removeEventListener('scroll', throttledHandleScroll.value)
    }
  })

  function handleScroll () {
    if (window.scrollY > 128) {
      if (window.scrollY > lastScroll.value + 20) {
        isShowHeader.value = false
      } else {
        isShowHeader.value = true
      }
    } else {
      isShowHeader.value = true
    }

    lastScroll.value = window.scrollY
  }

  const toggleNav = async () => {
    if (!isShowMenu.value) window.scrollTo(0, 0)

    isShowMenu.value = !isShowMenu.value
  }

  useHead({
    bodyAttrs: {
      class: computed(() => {
        return isShowMenu.value ? 'overflow-y-hidden max-h-screen' : ''
      }),
    },
  })
</script>

<template>
  <div :class="headerClasses">
    <div class="header__inner header__inner--desktop">
      <common-page-header-navigation/>
      <common-page-header-profile/>
    </div>

    <div class="header__inner header__inner--mobile">
      <div class="flex gap-4">
        <common-page-header-profile-cart/>
        <common-page-header-profile-avatar/>
      </div>

      <button @click="toggleNav" class="burger__button">
        <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
          <path
            fill-rule="evenodd"
            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
          ></path>
        </svg>
      </button>

      <transition name="slide-fade">
        <div v-if="isShowMenu" class="burger__menu">
          <div class="burger__menu-inner">
            <common-langswitcher/>
            <common-regionswitcher/>
            <common-page-header-profile-account/>
          </div>

          <common-page-header-navigation/>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
.header {
  @apply
    right-0 left-0 z-10
    bg-white
    header-height
    fixed bottom-full top-auto
    md:relative md:top-0 md:bottom-auto;

  &--is-show {
    .header__inner {
      @apply translate-y-full md:translate-y-0;
    }
  }

  &__inner {
    @apply
      flex items-center justify-between
      relative translate-y-0 transition-transform
      bg-white
      border-b border-solid border-b-separator
      w-full px-6 pt-4 pb-2;

    &--desktop {
      @apply hidden md:flex items-center justify-between px-[10%];
    }

    &--mobile {
      @apply flex md:hidden;
    }
  }
}

.burger {
  &__button {
    @apply md:hidden;
  }

  &__menu {
    @apply
      flex flex-col md:flex-row md:items-center
      container h-screen px-6 pt-2 pb-4 gap-8
      bg-white
      absolute top-full right-0
      border-t border-solid border-t-black
      md:hidden;

    &-inner {
      @apply flex justify-between;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
